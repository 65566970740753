.waiting-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: url('your-pattern-image-url-here'); */
  background-size: cover;
}

.waiting-text {
  color: black;
  font-size: 3em;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.waiting-text:hover {
  color: green;
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.main-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: green;
  color: white;
}

.main-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.waiting-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coming-soon-text {
  font-size: 2em; /* 글자 크기 키우기 */
  color: #ff4500; /* 색깔 추가 */
  margin-top: 10px;
}